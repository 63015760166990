<template>
  <div class="app_con">
    <el-form :model="goodForm" ref="goodForm" label-width="100px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="first">
          <el-form-item label="商品名称" prop="name">
            <el-input type="input" v-model="goodForm.name"></el-input>
          </el-form-item>

          <el-form-item label="关键词" prop="keyword">
            <el-input type="input" v-model="goodForm.keyword"></el-input>
          </el-form-item>
          <el-form-item label="商品主图" prop="image">
            <el-upload
              :action="action"
              list-type="picture-card"
              :on-success="handlePictureSuccess"
              :on-remove="handleRemove"
              :file-list="imageList"
              :limit="5"
              multiple
              :on-exceed="handlePictureExceed"
            >
              <i class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="品牌" prop="brand">
            <el-select v-model="goodForm.brand" filterable placeholder="请选择">
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.parameterName"
                :value="item.parameterName"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类" prop="classificationGoodsId">
            <el-cascader
              v-model="goodForm.classificationGoodsId"
              :options="options"
              :props="{ emitPath: false, label: 'name', value: 'id' }"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="是否上架" prop="isPutaway">
            <el-radio-group v-model="goodForm.isPutaway">
              <el-radio-button label="0">立即上架</el-radio-button>
              <el-radio-button label="1">放入仓库</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="footerSub">
            <el-button>保存</el-button
            ><el-button @click="markeData">下一步</el-button>
          </div>
        </el-tab-pane>
        <!--part2---->
        <el-tab-pane label="价格库存" name="second">
          <el-form-item label="销售价" prop="salesPrice">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="goodForm.goodsSpecificationAdd[0].goodsSkuAdd.salesPrice"
              ><template slot="append">元</template></el-input
            >
          </el-form-item>
          <el-form-item label="划线价" prop="originalPrice">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="
                goodForm.goodsSpecificationAdd[0].goodsSkuAdd.originalPrice
              "
              ><template slot="append">元</template></el-input
            >
          </el-form-item>
          <el-form-item label="成本价" prop="costPrice">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="goodForm.goodsSpecificationAdd[0].goodsSkuAdd.costPrice"
              ><template slot="append">元</template></el-input
            >
          </el-form-item>
          <el-form-item label="库存" prop="inventory">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="goodForm.goodsSpecificationAdd[0].goodsSkuAdd.inventory"
              ><template slot="append">件</template></el-input
            >
          </el-form-item>
          <el-form-item label="库存预警" prop="earlyWarning">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="
                goodForm.goodsSpecificationAdd[0].goodsSkuAdd.earlyWarning
              "
              ><template slot="append">件</template></el-input
            >
          </el-form-item>
          <el-form-item label="重量" prop="weight">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="goodForm.goodsSpecificationAdd[0].goodsSkuAdd.weight"
              ><template slot="append">KG</template></el-input
            >
          </el-form-item>
          <el-form-item label="体积" prop="volume">
            <el-input
              type="input"
              size="small"
              class="goodsInput"
              v-model="goodForm.goodsSpecificationAdd[0].goodsSkuAdd.volume"
            >
              <template slot="append">m³</template></el-input
            >
          </el-form-item>

          <!-- <el-form-item label="启用多规格" prop="isPutaway">
            <el-switch
              v-model="goodForm.isDg"
              active-color="#409EFF"
              inactive-color="#ccc"
            >
            </el-switch>
          </el-form-item> -->
          <!-- <div class="skuContent">
            <div class="skuList">
              <el-row :gutter="20">
                <el-col :span="8" v-for="(item, index) in skuList" :key="index">
                  <div class="item m-b-20">
                    <el-input placeholder="请输入内容" v-model="item.name">
                      <template slot="prepend">规格{{ index + 1 }}</template>
                      <el-button
                        slot="append"
                        icon="el-icon-delete"
                        @click="delValue(index)"
                        >删除</el-button
                      >
                    </el-input>
                    <div
                      class="tag"
                      v-for="(itemc, indexc) in item.value"
                      :key="indexc"
                    >
                      <el-input
                        class="tagInput"
                        size="mini"
                        v-model="item.value[indexc]"
                      >
                        <i
                          slot="append"
                          class="el-icon-delete pointer"
                          @click="delValue(index, indexc)"
                        ></i
                      ></el-input>
                    </div>

                    <el-button
                      size="mini"
                      @click="addSkuValue(index)"
                      class="m-t-10"
                      >新增规格值</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </div>
            <el-button @click="addSku">添加规格</el-button>
          </div> -->

          <el-button @click="creatrGoods">保存</el-button
          ><el-button @click="saveGoods">下一步</el-button>
        </el-tab-pane>
        <!--part3---->
        <el-tab-pane label="商品详情" name="third">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            name="img"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
          </el-upload>

          <quill-editor
            class="ql-editor"
            v-model="goodForm.content"
            ref="QuillEditor"
          >
          </quill-editor>
        </el-tab-pane>
        <!--part4---->
        <el-tab-pane label="商品参数" name="fourth">商品参数</el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/common';

export default {
  data() {
    return {
      action: '/api/file/upload',
      activeName: 'first',
      skuList: [
        { name: '颜色', value: ['红色', '蓝色'] },
        { name: '内存', value: ['128G', '256G', '512G'] },
        { name: '配件', value: ['标配', '套餐1'] }
      ],

      goodForm: {
        apiName: '/goodsBasicSetting/create',
        name: '',
        promotion: '',
        keyword: '',
        image: '',
        video: '',
        brand: '',
        serveGoodsId: 0,
        classificationGoodsId: 0,
        isPutaway: 0,
        sorts: 0,
        content: '你好周杰伦',
        storeId: '1594291389553430530',
        goodsSpecificationAdd: [
          {
            name: '单规格',
            value: '单规格',
            goodsSkuAdd: {
              name: '',
              image: '',
              subheading: '',
              salesPrice: '0.00',
              originalPrice: '0.00',
              costPrice: '0.00',
              inventory: 0,
              earlyWarning: 0,
              weight: 0,
              volume: 0
            },
            subheading: ''
          }
        ]
      },
      brandList: [],
      imageList: [],
      options: [],
      recursionTime: 0,
      treeData: [],

      curLevel: 'children'
    };
  },
  created() {},

  mounted() {
    this.getBrandList();
    this.getClassification();
  },
  methods: {
    uploadSuccess(res) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res.code === 100 && res.result) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.result[0].url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败！');
      }
    },

    //价格格式化
    priceFix(e) {
      console.log(e);
    },
    async creatrGoods() {
      console.log(this.goodForm);

      let res = await api.commonPost(this.goodForm);
      if (res.code == 200) {
        this.$message.success(res.message);
      }
    },

    markeData() {
      let total = 1;
      this.skuList.forEach(item => {
        total = total * item.value.length;
      });

      console.log(total);
    },

    saveGoods() {
      for (let index = 0; index < this.skuList[0].value.length; index++) {
        let object = { name: '', value: '', children: [] };
        // let skuName = this.skuList[0].value[index];
        (object.name = this.skuList[0].name),
          (object.value = this.skuList[0].value[index]),
          this.treeData.push(object);
        //得到首层数据后，判断是否有2层

        if (this.skuList.length > 1) {
          for (let i = 0; i < this.skuList[1].value.length; i++) {
            this.treeData[index].children.push({
              name: this.skuList[1].name,
              value: this.skuList[1].value[i],
              children: []
            });
          }
          //这里判断第三层
          if (this.skuList.length > 2) {
            for (let y = 0; y < this.skuList[2].value.length; y++) {
              this.treeData[index].children.forEach((item, num) => {
                this.treeData[index].children[num].children.push({
                  name: this.skuList[2].name,
                  value: this.skuList[2].value[y]
                });
              });
            }
          }
        }
      }

      console.log(this.treeData);
    },

    digui(index, num) {
      for (let i = 0; i < this.skuList[num].value.length; i++) {
        this.treeData[index].children.push({
          name: this.skuList[1].name,
          value: this.skuList[1].value[i],
          children: []
        });
      }

      if (num < this.skuList.length) {
        num++;
      }
    },

    mapper(name, value, deep, previousValue) {
      let fullName = previousValue + value;
      const last = deep >= this.skuList.length;
      //  不是最后一层
      if (!last) {
        fullName += ' ';
        const next = this.skuList[deep++];
        const children = next.value?.map(val =>
          this.mapper(next.name, val, deep, fullName)
        );
        return { name, value, children };
      } else {
        // 最后一层
        return {
          name,
          value,
          goodsSkuAdd: {
            name: fullName,
            subheading: fullName,
            salesPrice: '',
            originalPrice: '',
            costPrice: '',
            inventory: '',
            earlyWarning: '',
            weight: '',
            volume: ''
          }
        };
      }
    },
    getTreeData() {},

    //循环构建数据,倒着来循环，正的不会写！
    getSkuDataByRecursion() {
      for (let index = this.skuList.length; index > 0; index--) {
        if (index == this.skuList.length) {
          for (let i = 0; i < this.skuList[index - 1].value.length; i++) {
            this.treeData.push({
              name: this.skuList[index - 1].value[i],
              value: this.skuList[index - 1].value[i],
              goodsSkuAdd: {
                subheading: '',
                salesPrice: '',
                originalPrice: '',
                costPrice: '',
                inventory: '',
                earlyWarning: '',
                weight: '',
                volume: ''
              }
            });
          }
        } else {
          let arr = [];
          for (let i = 0; i < this.skuList[index - 1].value.length; i++) {
            arr.push({
              name: this.skuList[index - 1].name,
              value: this.skuList[index - 1].value[i],
              children: this.treeData
            });
          }

          this.treeData = arr;
        }
      }

      console.log(JSON.stringify(this.treeData));
      //第一次
    },

    //删除规格
    delValue(index, indexc) {
      console.log(index, indexc);
      if (indexc || indexc == 0) {
        this.skuList[index].value.splice(indexc, 1);
      } else {
        this.skuList.splice(index, 1);
      }
    },
    doubleclick() {
      console.log('shuangjil');
    },
    removeSkuTag() {
      console.log('单机了');
    },

    addSkuValue(index) {
      this.skuList[index].value.push('');
    },
    addSku() {
      this.skuList.push({ name: '', value: [] });
    },
    handlePictureSuccess(response, file, fileList) {
      //这里会出现报错

      let arr = [];

      fileList.forEach(element => {
        arr.push(element.response.data.path);
      });

      this.goodForm.image = arr.join(',');
    },
    handleRemove(file, fileList) {
      let arr = [];
      fileList.forEach(element => {
        arr.push(element.response.data.path);
      });

      this.goodForm.image = arr.join(',');
    },
    handlePictureExceed() {},

    async getClassification() {
      let res = await api.commonGet({ apiName: '/classificationGoods' });
      if (res.code == 200) {
        this.options = res.data;
        console.log('res', res);
      }
    },

    async getBrandList() {
      try {
        let res = await api.commonGet({
          apiName: '/systemDataDictionaryItem',
          systemDataDictionaryId: '1595246363145887746'
        });
        this.brandList = res.data.data;
        console.log('zidian', res.data.data);
      } catch (error) {
        console.log(error);
        // this.$message.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.goodsInput {
  width: 200px;
}
.skuList .tag {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
}
.tagInput {
  width: 95px;
}
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
